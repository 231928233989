import React, { Component } from 'react';

class Logo extends Component {
  
  render() {

    return (
      <div className="logo__container">
        <div className="logo__positioner">
            <div className="logo__drone"></div>
            <div className="logo__box">
                <p className="logo__top-text">airdrop</p>
                <p className="logo__bottom-text">hampers</p>
            </div>        
        </div>
      </div>
    );
  }
}

export default Logo;
