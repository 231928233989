import React from 'react';
import './App.css';
import "./styles/scss/app.scss";
import Header from './components/Header';
import Logo from './components/Logo';
import Hero from './components/Hero';

function App() {
  return (
    <div className="App">
      <Header />
      <Logo />
      <Hero />
    </div>
  );
}

export default App;
