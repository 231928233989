import React, { Component } from 'react';

// Define your class-based component
class Header extends Component {
  
  render() {

    return (
      <div className="header">
       <p>free mainland uk delivery*</p>
       <p>100% recyleable transit packaging</p>
      </div>
    );
  }
}

export default Header;
