import React, { Component } from 'react';
import ButtonCta from './UI/buttonCta';

interface CategoryDemoState {
  selectedCategory: string;
}

class Hero extends Component<{}, CategoryDemoState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      selectedCategory: "boozey"
    };
  }

  test = () => {
    console.log('replace with animation trigger');
  }

  changeCategory = (cat: string) => {
    this.setState({
      selectedCategory: cat
    });
    console.log(cat);
  }

  render() {
    const { selectedCategory } = this.state;
    const categories = [
      {
        "text": "wellness",
        "class": "wellness"
      },
      {
        "text": "snack monster",
        "class":"snack-monster",
      },
      {
        "text": "tea total",
        "class": "tea-total",
      },
      {
        "text": "sweet tooth",
        "class": "sweet-tooth",
      },
      {
        "text": "posh nosh",
        "class": "posh-nosh",
      },
      {
        "text": "boozey",
        "class": "boozey",
    }
  ];
    return (
      <div className={"hero --" + selectedCategory}>
        <div className="hero__card-outer">
          <div className="hero__cta-card">
            <h1>build your custom hamper in minutes</h1>
          </div>
          <ButtonCta text="lets go!" buttonAction={this.test} />
        </div>

        <div className="category-container">
          {categories.map((category, index) => (
            <div key={index} className="category-demo-button" onMouseOver={() => this.changeCategory(category.class)}>
              <p>{category.text}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Hero;
