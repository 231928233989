import React, { Component } from 'react';

interface ButtonCtaProps {
    text: string;
    link?: string;
    buttonAction: () => void;
}

class ButtonCta extends Component<ButtonCtaProps> {
    render() {
      const {text, buttonAction} = this.props;

    return (
      <div className="button-cta" onClick={() => buttonAction()}>
       <p>{text}</p>
      </div>
    );
  }
}

export default ButtonCta;
